import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import Navbar from '../components/_App/Navbar'
import MainBanner from '../components/Home/MainBanner'
// import Features from '../components/Home/Features'
// import ServicesArea from '../components/Home/ServicesArea'
// import OurFeatures from '../components/Home/OurFeatures'
// import Team from '../components/Common/Team'
// import FunFactsArea from '../components/Common/FunFactsArea'
// import RecentWorks from '../components/Common/RecentWorks'
// import PricingStyleOne from '../components/PricingPlans/PricingStyleOne'
// import Feedback from '../components/Common/Feedback'
// import Partner from '../components/Common/Partner'
// import BlogPost from '../components/Common/BlogPost'
import Cta from '../components/Common/Cta'
import Footer from '../components/_App/Footer'

const Home = () => (
  <Layout>
    <Seo title='GeoML.io Home' />
    <Navbar />
    <MainBanner />
    {/* <Features /> */}
    {/* <ServicesArea />
    <OurFeatures /> */}
    {/* <Team />
    <FunFactsArea />
    <RecentWorks /> */}
    {/* <PricingStyleOne /> */}
    {/* <Feedback /> */}
    {/* <Partner /> */}
    {/* <BlogPost /> */}
    <Cta />
    <Footer />
  </Layout>
)

export default Home
